import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Auth
import AuthActions from 'Containers/Auth/Auth.actions';
import AuthSelectors from 'Containers/Auth/Auth.selectors';

//styles
import { StyledAppBar, StyledToolbar, StyledButton } from "Components/styles/Navbar.styles";

const NavBar: FC = () => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const [width, setWidth] = useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	const isMobile = width <= 768;

	const location = useLocation();
	const { pathname } = location;
	const locationIsHome = pathname === "/";
	const locationIsAdminpanel = pathname === "/adminpanel";
	const locationIsQuestions = pathname === "/questions";
	const locationIsLogin = pathname === "/login";
	const locationIsRegister = pathname === "/register";
	const locationIsUser = pathname === "/user";


	const isAuthenticated = Boolean(useSelector(AuthSelectors.getToken));
	const userRole = useSelector(AuthSelectors.getRole);
	const permissions = useSelector(AuthSelectors.getPermissions);
	const questionsPermission = Boolean(permissions?.find((permission: any) => permission.name === "questions" && permission.isActive));

	const handleClick = (event: any) => {
	setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
	setAnchorEl(null);
	};

	const _onLogout = () => {
		dispatch(AuthActions.LOGOUT.request());
	};

	const _onLogoutMenu = () => {
		dispatch(AuthActions.LOGOUT.request());
		handleClose();
	};

	return (
	<StyledAppBar>
		<StyledToolbar>
			<IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick} sx={{ mr: 2, display: isMobile ? "block" : "none" }}>
				<MenuIcon 
					aria-label="menu"
					aria-controls="basic-menu"
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
				/>
			</IconButton>
			<Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1, textDecoration: locationIsHome ? "underlined" : "none", color: "inherit" }}>
				Diagnosetool
			</Typography>
			{!isMobile && (
				<>
					{userRole === "admin" && <StyledButton className={locationIsAdminpanel ? "selected" : ""} color="inherit" component={Link} to="/adminpanel">Benutzerverwaltung</StyledButton>}
					{isAuthenticated
						? (
							<>
								{questionsPermission && <StyledButton className={locationIsQuestions ? "selected" : ""} color="inherit" component={Link} to="/questions">Fragen</StyledButton>}
								<StyledButton className={locationIsUser ? "selected" : ""} color="inherit" component={Link} to="/user">Konto</StyledButton>
								<StyledButton color="inherit" onClick={_onLogout}>Logout</StyledButton>
							</>
						)
						: (
							<>
								<StyledButton className={locationIsLogin ? "selected" : ""} color="inherit" component={Link} to="/login">Login</StyledButton>
								<StyledButton className={locationIsRegister ? "selected" : ""} color="inherit" component={Link} to="/register">Register</StyledButton>
							</>
						)
					}
				</>
			)}
			<MenuDropdownComponent
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				userRole={userRole}
				isAuthenticated={isAuthenticated}
				locationIsAdminpanel={locationIsAdminpanel}
				locationIsQuestions={locationIsQuestions}
				locationIsLogin={locationIsLogin}
				locationIsRegister={locationIsRegister}
				locationIsUser={locationIsUser}
				questionsPermission={questionsPermission}
				_onLogoutMenu={_onLogoutMenu}
			/>
		</StyledToolbar>
	</StyledAppBar>
	);
};

interface MenuDropdownComponentProps {
	anchorEl: any;
	open: boolean;
	handleClose: () => void;
	userRole: "admin" | "employee" | "user" | null;
	isAuthenticated: boolean;
	locationIsAdminpanel: boolean;
	locationIsQuestions: boolean;
	locationIsLogin: boolean;
	locationIsRegister: boolean;
	locationIsUser: boolean;
	questionsPermission: boolean;
	_onLogoutMenu: () => void;
}

const MenuDropdownComponent: FC<MenuDropdownComponentProps> = ({
	anchorEl,
	open,
	handleClose,
	userRole,
	isAuthenticated,
	locationIsAdminpanel,
	locationIsQuestions,
	locationIsLogin,
	locationIsRegister,
	locationIsUser,
	questionsPermission,
	_onLogoutMenu
}) => {
	if (!isAuthenticated) {
		return (
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
				'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem onClick={handleClose} component={Link} to="/login" sx={{ fontWeight: locationIsLogin ? "bold" : "normal" }}>Login</MenuItem>
				<MenuItem onClick={handleClose} component={Link} to="/register" sx={{ fontWeight: locationIsRegister ? "bold" : "normal" }}>Register</MenuItem>
			</Menu>
		);
	}
	return (
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
			'aria-labelledby': 'basic-button',
			}}
		>
			{userRole === "admin" && <MenuItem onClick={handleClose} component={Link} to="/adminpanel" sx={{ fontWeight: locationIsAdminpanel ? "bold" : "normal" }}>Benutzerverwaltung</MenuItem>}
			{questionsPermission && <MenuItem onClick={handleClose} component={Link} to="/questions" sx={{ fontWeight: locationIsQuestions ? "bold" : "normal" }}>Fragen</MenuItem>}
			<MenuItem onClick={handleClose} component={Link} to="/user" sx={{ fontWeight: locationIsUser ? "bold" : "normal" }}>Konto</MenuItem>
			<MenuItem onClick={_onLogoutMenu}>Logout</MenuItem>
		</Menu>
	);
};

export default NavBar;