import { takeLatest, put, call } from "redux-saga/effects";

//actions
import CoreActions from "./Core.actions";

import { initializeStore } from "./store";

function* onUpdateConfig({ payload }: ReturnType<typeof CoreActions.UPDATE_CONFIG.action>) {
	const config = payload;
	yield put(CoreActions.SET_CONFIG.action(config));
	yield call(initializeStore);
}

export default function* rootSagas() {
	yield takeLatest(CoreActions.UPDATE_CONFIG.ACTION, onUpdateConfig);
}
