//types
import { AppState } from "Redux/definitions/AppState";
import { defaultState, StateAuth} from "./Auth.reducer";

type CompleteState = AppState & { Auth: StateAuth };

export const getAuth = (state: CompleteState) => state.Auth || defaultState;

export const getAuthIsLoading = (state: CompleteState) => getAuth(state).isLoading;
export const getAuthToken = (state: CompleteState) => getAuth(state).token;
export const getAuthUuid = (state: CompleteState) => getAuth(state).uuid;
export const getAuthRole = (state: CompleteState) => getAuth(state).role;
export const getAuthPermissions = (state: CompleteState) => getAuth(state).permissions;

export default {
	getIsLoading: getAuthIsLoading,
	getToken: getAuthToken,
	getUuid: getAuthUuid,
	getRole: getAuthRole,
	getPermissions: getAuthPermissions,
};
