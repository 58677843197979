import localForage from "localforage";
import { persistReducer, createMigrate } from "redux-persist";

//actions
import AdminpanelActions from "./Adminpanel.actions";

//constants
import { CONFIG_MAP } from "Constants/Config";

//reducer
import AdminpanelReducer from "./Adminpanel.reducer";

//sagas
import AdminpanelSagas from "./Adminpanel.sagas";

const AdminpanelPersistConfig = {
	key: "Adminpanel",
	storage: localForage.createInstance({ name: "AppAdminpanel" }),
	timeout: null as unknown as undefined,
	version: 0,
	migrate: createMigrate({
		0: (state) => state,
	}),
};

export const initializeAdminpanel = (config: Record<keyof typeof CONFIG_MAP, boolean>) => {

	const modules: any = [];

	if (config[CONFIG_MAP.adminpanel]) {
		modules.push({
			id: "Adminpanel",
			reducerMap: {
				//Adminpanel: persistReducer(AdminpanelPersistConfig, AdminpanelReducer),
				Adminpanel: AdminpanelReducer,
			},
			sagas: [AdminpanelSagas],
			initialActions: [AdminpanelActions.INITIATE.action()],
			finalActions: [AdminpanelActions.UNINITIATE.action()],
		});
	}

	return modules;
};
