import localForage from "localforage";
import { persistReducer, createMigrate } from "redux-persist";

//actions
import AuthActions from "./Auth.actions";

//constants
import { CONFIG_MAP } from "Constants/Config";

//reducer
import AuthReducer from "./Auth.reducer";

//sagas
import AuthSagas from "./Auth.sagas";

//functions
//import { initFunctions } from "./Panel.functions";

const AdminpanelPersistConfig = {
	key: "Auth",
	storage: localForage.createInstance({ name: "AppAuth" }),
	timeout: null as unknown as undefined,
	version: 0,
	migrate: createMigrate({
		0: (state) => state,
	}),
};

export const initializeAuth = (config: Record<keyof typeof CONFIG_MAP, boolean>) => {

	const modules: any = [{
		id: "Auth",
		reducerMap: {
			//Auth: persistReducer(AdminpanelPersistConfig, AuthReducer),
			Auth: AuthReducer,
		},
		sagas: [AuthSagas],
		initialActions: [AuthActions.INITIATE.action()],
		finalActions: [AuthActions.UNINITIATE.action()],
	}];

	return modules;
};
