//actions
import AuthActions from "./Auth.actions";

export interface StateAuth {
	isLoading: boolean;
	uuid: string | null;
	token: string | null;
	role: "admin" | "employee" | "user" | null;
	permissions: PermissionReduxType[] | null;
}

export const defaultState: StateAuth = {
	isLoading: false,
	uuid: null,
	token: null,
	role: null,
	permissions: null,
};

type AuthActionTypes = ReturnType<typeof AuthActions.SET_IS_LOADING.action>
	| ReturnType<typeof AuthActions.LOGIN.success>
	| ReturnType<typeof AuthActions.LOGOUT.success>
	| ReturnType<typeof AuthActions.SET_USER_ROLE.action>
	| ReturnType<typeof AuthActions.SET_USER_PERMISSIONS.action>;

export default (
	state: StateAuth = defaultState,
	action: AuthActionTypes,
): StateAuth => {
	switch (action.type) {
		case AuthActions.SET_IS_LOADING.ACTION:
			return {
				...state,
				isLoading: action.payload,
			};
			
		case AuthActions.LOGIN.SUCCESS:
			return {
				...state,
				uuid: action.payload.uuid,
				token: action.payload.token,
				role: action.payload.role,
				permissions: action.payload.permissions,
			};

		case AuthActions.LOGOUT.SUCCESS:
			return {
				...state,
				uuid: null,
				token: null,
				role: null,
				permissions: null,
			};

		case AuthActions.SET_USER_ROLE.ACTION:
			return {
				...state,
				role: action.payload,
			};

		case AuthActions.SET_USER_PERMISSIONS.ACTION:
			return {
				...state,
				permissions: action.payload,
			};

		default:
			return state;
	}
};
