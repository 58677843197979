import { styled, Button, ButtonProps } from "@mui/material";
import { Link } from "react-router-dom";

//components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

interface StyledButtonProps extends ButtonProps {
	component?: typeof Link;
	to?: string;
}

export const StyledAppBar = styled(AppBar)(() => ({
	height: "7vh",
	minHeight: "4vw",
	position: "fixed",
}));

export const StyledToolbar = styled(Toolbar)(() => ({
	height: "7vh",
	minHeight: "4vw !important",
}));

export const StyledButton = styled(Button)<StyledButtonProps>(() => ({
	"&.selected": {
		fontWeight: "bold",
		borderBottom: "2px solid white",
		borderRadius: "0",
	},
}));
