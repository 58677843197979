import { put, call, takeLatest, select, take, delay } from "redux-saga/effects";
import { AsyncReturnType } from "type-fest";

//actions
import AuthActions from "Containers/Auth/Auth.actions";
import CoreActions from "Redux/Core.actions";

//selectors
import AuthSelectors from "Containers/Auth/Auth.selectors";

//utils
import { loginRequest, registerRequest, logoutRequest } from "./Auth.utils";
import { ERROR_MESSAGE } from "Constants/ErrorMessage";

function* onInitiate() {
	yield take((action: any) => action.type === "persist/REHYDRATE" && action.key === "root");
	yield put(AuthActions.SET_IS_LOADING.action(false));
}

function* onUninitiate() {
	console.log("onUninitiate");
	//yield call(SynchronizationFunctions.RemoveEventListener, "PRODUCTS", "UpdatePayOutList");
	//yield call(SynchronizationFunctions.RemoveEventListener, "POS_CONFIG", "UpdatePayOutListPosConfig");
}

function* onLogin({ payload }: ReturnType<typeof AuthActions.LOGIN.request>) {
	yield put(AuthActions.SET_IS_LOADING.action(true));
	const { email, password } = payload;
	try {
		const response: AsyncReturnType<typeof loginRequest> = yield call(loginRequest, email, password);
		if ("data" in response) {
			yield put(AuthActions.LOGIN.success({
				uuid: response.data.uuid,
				token: response.data.token,
				role: response.data.role,
				permissions: response.data.permissions
			}));
			yield put(CoreActions.SET_ROUTE.action("/"));
			yield put(CoreActions.DISPLAY_ERROR.action({ message: ERROR_MESSAGE.LOGIN_SUCCESS, type: "success", title: "Erfolg" }));
		} else if ("error" in response) {
			const errorMessage = (() => {
				if (response.error === "Invalid email or password") return ERROR_MESSAGE.INVALID_EMAIL_OR_PASSWORD;
				if (response.error === "Missing fields") return ERROR_MESSAGE.MISSING_FIELDS;
				return response.error;
			})();
			yield put(CoreActions.DISPLAY_ERROR.action({ message: errorMessage }));
			yield put(AuthActions.LOGIN.failure({ error: response.error }));
		}
	} catch (error) {
		console.error(error);
	} finally {
		yield put(AuthActions.SET_IS_LOADING.action(false));
	}
}

function* onRegister({ payload }: ReturnType<typeof AuthActions.REGISTER.request>) {
	yield put(AuthActions.SET_IS_LOADING.action(true));
	const { email, password } = payload;
	try {
		const response: AsyncReturnType<typeof registerRequest> = yield call(registerRequest, email, password);
		if ("data" in response) {
			yield put(AuthActions.REGISTER.success());
			yield put(CoreActions.DISPLAY_ERROR.action({ message: ERROR_MESSAGE.REGISTRATION_SUCCESS, type: "success", title: "Erfolg" }));
			yield put(CoreActions.SET_ROUTE.action("/login"));
		} else if ("message" in response) {
			yield put(CoreActions.DISPLAY_ERROR.action({ message: response.message }));
			yield put(AuthActions.REGISTER.failure({ error: response.message }));
		}
	} catch (error) {
		console.error(error);
	} finally {
		yield put(AuthActions.SET_IS_LOADING.action(false));
	}
}

function* onLogout() {
	yield put(AuthActions.SET_IS_LOADING.action(true));
	const token: ReturnType<typeof AuthSelectors.getToken> = yield select(AuthSelectors.getToken);
	if (!token) return;
	try {
		const response: AsyncReturnType<typeof logoutRequest> = yield call(logoutRequest, token);
		yield put(CoreActions.DISPLAY_ERROR.action({ message: ERROR_MESSAGE.USER_LOGGED_OUT, type: "warning", title: "Message" }));
	} catch (error) {
		console.error(error);
	} finally {
		yield put(AuthActions.LOGOUT.success());
		yield put(AuthActions.SET_IS_LOADING.action(false));
	}
}

export default function* rootSagas() {
	yield takeLatest(AuthActions.INITIATE.ACTION, onInitiate);
	yield takeLatest(AuthActions.UNINITIATE.ACTION, onUninitiate);
	yield takeLatest(AuthActions.LOGIN.REQUEST, onLogin);
	yield takeLatest(AuthActions.REGISTER.REQUEST, onRegister);
	yield takeLatest(AuthActions.LOGOUT.REQUEST, onLogout);
}
