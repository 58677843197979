import localForage from "localforage";
import { persistReducer, createMigrate } from "redux-persist";

//actions
import QuestionsActions from "./Questions.actions";

//constants
import { CONFIG_MAP } from "Constants/Config";

//reducer
import QuestionsReducer from "./Questions.reducer";

//sagas
import QuestionsSagas from "./Questions.sagas";

const QuestionsPersistConfig = {
	key: "Questions",
	storage: localForage.createInstance({ name: "AppQuestions" }),
	timeout: null as unknown as undefined,
	version: 0,
	migrate: createMigrate({
		0: (state) => state,
	}),
};

export const initializeQuestions = (config: Record<keyof typeof CONFIG_MAP, boolean>) => {

	const modules: any = [];

	if (config[CONFIG_MAP.questions]) {
		modules.push({
			id: "Questions",
			reducerMap: {
				//Questions: persistReducer(QuestionsPersistConfig, QuestionsReducer),
				Questions: QuestionsReducer,
			},
			sagas: [QuestionsSagas],
			initialActions: [QuestionsActions.INITIATE.action()],
			finalActions: [QuestionsActions.UNINITIATE.action()],
		});
	}

	return modules;
};
