import { styled } from "@mui/material";

export const FinishMessage = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	fontSize: '1.5rem',
	color: 'green',
});
