import QuestionsActions from "./Questions.actions";

export interface StateQuestions {
	isLoading: boolean;
	quesions: QuestionReduxType[] | null;
	catalogs: QuestionCatalogReduxType[] | null;
	alreadySelectedCatalogs: boolean;
	alreadyAnsweredQuestions: boolean;
	givenAnswers: Array<{
		uuid: string;
		email: string;
		answers: QuestionReduxType[];
	}> | QuestionReduxType[] | null;

	users: UserReduxType[] | null;
}

export const defaultState: StateQuestions = {
	isLoading: false,
	quesions: null,
	catalogs: null,
	alreadySelectedCatalogs: false,
	alreadyAnsweredQuestions: false,
	givenAnswers: null,

	users: null,
};

type QuestionsActionTypes = ReturnType<typeof QuestionsActions.SET_IS_LOADING.action>
	| ReturnType<typeof QuestionsActions.SET_CATALOGS.action>
	| ReturnType<typeof QuestionsActions.SET_QUESTIONS.action>
	| ReturnType<typeof QuestionsActions.SET_GIVEN_ANSWERS.action>
	| ReturnType<typeof QuestionsActions.SET_ALREADY_SELECTED_CATALOGS.action>
	| ReturnType<typeof QuestionsActions.SET_ALREADY_ANSWERED_QUESTIONS.action>;

export default (
	state: StateQuestions = defaultState,
	action: QuestionsActionTypes,
): StateQuestions => {
	switch (action.type) {
		case QuestionsActions.SET_IS_LOADING.ACTION:
			return {
				...state,
				isLoading: action.payload,
			};

		case QuestionsActions.SET_CATALOGS.ACTION:
			return {
				...state,
				catalogs: action.payload,
			};

		case QuestionsActions.SET_QUESTIONS.ACTION:
			return {
				...state,
				quesions: action.payload,
			};

		case QuestionsActions.SET_GIVEN_ANSWERS.ACTION:
			return {
				...state,
				givenAnswers: action.payload,
			};

		case QuestionsActions.SET_ALREADY_SELECTED_CATALOGS.ACTION:
			return {
				...state,
				alreadySelectedCatalogs: action.payload,
			};

		case QuestionsActions.SET_ALREADY_ANSWERED_QUESTIONS.ACTION:
			return {
				...state,
				alreadyAnsweredQuestions: action.payload,
			};

		default:
			return state;
	}
};
