import { Request } from "Utils/axiosRequest";

type GetUserRequestResponse = {
	message: string;
	data: any[];
} | {
	error: string;
};

type fetchUserDataResponse = {
	message: string;
	data: {
		hasAlreadySelectedCatalogs?: boolean;
		questions: QuestionReduxType[];
		catalogs: QuestionCatalogReduxType[];
		givenAnswers: Array<{
			uuid: string;
			email: string;
			answers: QuestionReduxType[];
		}> | QuestionReduxType[] | null;

	};
} | {
	error: string;
};

export const fetchUserData = async (token: string): Promise<fetchUserDataResponse> => {
	const response = await Request.toApi({
		url: "api/questions/",
		method: "GET",
		token,
	});

	const returnData: fetchUserDataResponse = (() => {
		if ("has_already_selected_catalogs" in response.data) {
			return {
				message: response.message,
				data: {
					hasAlreadySelectedCatalogs: response.data.has_already_selected_catalogs,
					questions: response.data.questions.map((question: any) => ({
						questionCatalogId: question.question_catalog_id,
						questionCatalogName: question.question_catalog_name,
						questionId: question.question_id,
						questionName: question.question_name,
						answerId: question.answer_id,
						answerName: question.answer_name,
						isCorrect: question.is_correct,
					})),
					catalogs: response.data.catalogs.map((catalog: any) => ({
						id: catalog.question_catalog_id,
						name: catalog.question_catalog_name,
						hasAccess: catalog.has_access,
					})),
					givenAnswers: response.data.given_answers.map((answer: any) => ({
						questionCatalogId: answer.question_catalog_id,
						questionCatalogName: answer.question_catalog_name,
						questionId: answer.question_id,
						questionName: answer.question_name,
						answerId: answer.answer_id,
						answerName: answer.answer_name,
						isCorrect: answer.is_correct,
					})),
				},
			};
		} else {
			return {
				message: response.message,
				data: {
					questions: response.data.questions.map((question: any) => ({
						questionCatalogId: question.question_catalog_id,
						questionCatalogName: question.question_catalog_name,
						questionId: question.question_id,
						questionName: question.question_name,
						answerId: question.answer_id,
						answerName: question.answer_name,
						isCorrect: question.is_correct,
					})),
					catalogs: response.data.catalogs.map((catalog: any) => ({
						id: catalog.question_catalog_id,
						name: catalog.question_catalog_name,
						hasAccess: catalog.has_access,
					})),
					givenAnswers: response.data.given_answers.map((answer: any) => ({
						uuid: answer.uuid,
						email: answer.email,
						answers: answer.answers.map((question: any) => ({
							questionCatalogId: question.question_catalog_id,
							questionCatalogName: question.question_catalog_name,
							questionId: question.question_id,
							questionName: question.question_name,
							answerId: question.answer_id,
							answerName: question.answer_name,
							isCorrect: question.is_correct,
						})),
					})),
				},
			};
		}
	})();

	return returnData;
};

export const upsertCatalogs = async (
	token: string,
	payload: { question_catalog_ids: number[] }
): Promise<GetUserRequestResponse> => {
	const response = await Request.toApi({
		url: "api/questions/setQuestionCatalog.php",
		method: "POST",
		token,
		data: payload,
	});

	return response;
}

export const upsertAnswers = async (
	token: string,
	payload: { answers: Array<{ answer_id: number, answer: number }> }
): Promise<GetUserRequestResponse> => {
	const response = await Request.toApi({
		url: "api/questions/setAnswers.php",
		method: "POST",
		token,
		data: payload,
	});

	return response;
}

