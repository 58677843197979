//actions
import CoreActions from "./Core.actions";

export interface StateCore {
	config: Record<string, boolean>;
	routerLocation: string;
	alertDialogProps: AlertDialogReduxType | null;
}

export const defaultState: StateCore = {
	config: {},
	routerLocation: "",
	alertDialogProps: null,
};

type TestModuleActionTypes = ReturnType<typeof CoreActions.SET_CONFIG.action>
	| ReturnType<typeof CoreActions.SET_ROUTE.action>
	| ReturnType<typeof CoreActions.DISPLAY_ERROR.action>;

export default (
	state: StateCore = defaultState,
	action: TestModuleActionTypes,
): StateCore => {
	switch (action.type) {
		case CoreActions.SET_CONFIG.ACTION:
			return {
				...state,
				config: action.payload,
			};

		case CoreActions.SET_ROUTE.ACTION:
			return {
				...state,
				routerLocation: action.payload,
			};

		case CoreActions.DISPLAY_ERROR.ACTION:
			return {
				...state,
				alertDialogProps: action.payload,
			};

		default:
			return state;
	}
};
