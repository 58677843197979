import { put, call, takeLatest, select, take } from "redux-saga/effects";
import { AsyncReturnType } from "type-fest";

//actions
import QuestionsActions from "Containers/Questions/Questions.actions";

//selectors
import AuthSelectors from "Containers/Auth/Auth.selectors";

//utils
import { fetchUserData, upsertCatalogs, upsertAnswers } from "./Questions.utils";

function* onInitiate() {
	yield take((action: any) => action.type === "persist/REHYDRATE" && action.key === "root");
	console.log("onInitiate - Questions");
	yield put(QuestionsActions.FETCH_USER_DATA.action());
}

function* onUninitiate() {
	console.log("onUninitiate");
}

function* onFetchUserData() {
	yield put(QuestionsActions.SET_IS_LOADING.action(true));
	const token: ReturnType<typeof AuthSelectors.getToken> = yield select(AuthSelectors.getToken);
	if (token) {
		try {
			const response: AsyncReturnType<typeof fetchUserData> = yield call(fetchUserData, token);
			if ("data" in response) {
				yield put(QuestionsActions.SET_QUESTIONS.action(response.data.questions));
				yield put(QuestionsActions.SET_CATALOGS.action(response.data.catalogs));
				yield put(QuestionsActions.SET_GIVEN_ANSWERS.action(response.data.givenAnswers));
				yield put(QuestionsActions.SET_ALREADY_SELECTED_CATALOGS.action(response.data.hasAlreadySelectedCatalogs ?? false));
				yield put(QuestionsActions.SET_ALREADY_ANSWERED_QUESTIONS.action(Boolean(response.data.givenAnswers?.length)));
			}
		} catch (error) {
			console.error(error);
		}
	}
	yield put(QuestionsActions.SET_IS_LOADING.action(false));
}

function* onUpsertCatalogs({ payload }: ReturnType<typeof QuestionsActions.UPSERT_CATALOGS.request>) {
	yield put(QuestionsActions.SET_IS_LOADING.action(true));
	const token: ReturnType<typeof AuthSelectors.getToken> = yield select(AuthSelectors.getToken);
	const transformedPayload = Object.keys(payload).filter((key) => payload[Number(key)]).map(Number);
	if (token) {
		try {
			yield call(upsertCatalogs, token, { question_catalog_ids: transformedPayload });
		} catch (error) {
			console.error(error);
		} finally {
			yield put(QuestionsActions.FETCH_USER_DATA.action());
		}
	}
	yield put(QuestionsActions.SET_IS_LOADING.action(false));
}

function* onUpsertAnswers({ payload }: ReturnType<typeof QuestionsActions.UPSERT_ANSWERS.request>) {
	yield put(QuestionsActions.SET_IS_LOADING.action(true));
	const token: ReturnType<typeof AuthSelectors.getToken> = yield select(AuthSelectors.getToken);
	if (token) {
		const data = Object.keys(payload).map((key) => ({
			answer_id: payload[Number(key)],
			answer: 1
		}));
		try {
			yield call(upsertAnswers, token, { answers: data });
		} catch (error) {
			console.error(error);
		} finally {
			yield put(QuestionsActions.FETCH_USER_DATA.action());
		}
	}
	yield put(QuestionsActions.SET_IS_LOADING.action(false));
}

export default function* rootSagas() {
	yield takeLatest(QuestionsActions.INITIATE.ACTION, onInitiate);
	yield takeLatest(QuestionsActions.UNINITIATE.ACTION, onUninitiate);
	yield takeLatest(QuestionsActions.FETCH_USER_DATA.ACTION, onFetchUserData);
	yield takeLatest(QuestionsActions.UPSERT_CATALOGS.REQUEST, onUpsertCatalogs);
	yield takeLatest(QuestionsActions.UPSERT_ANSWERS.REQUEST, onUpsertAnswers);
}
