import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { Checkbox } from '@mui/material';

//HOC
import ConfigCheck from 'HOC/ConfigCheck';

//constants
import { CONFIG_MAP } from 'Constants/Config';

//selectors
import { getQuestionsGivenAnswers } from 'Containers/Questions/Questions.selectors';

//style
import {
	StyledAccordion,
	StyledAccordionSummary,
	StyledAccordionDetails,
	StyledAccordionDetailsWrapper,
	StyledAnswerCaluculation,
	StyledTableRow,
 } from './QuestionsPageAdmin.style';

const QuestionsPage: FC = () => {
	const givenAnswers = useSelector(getQuestionsGivenAnswers);

	const [expanded, setExpanded] = useState<string | false>(false);

	const users = givenAnswers?.map((user) => {
		if (!("answers" in user)) {
			return null;
		}
		const catalogs = user.answers.reduce<{ [key: string]: { catalogName: string, correctAnswers: number, inCorrectAnswers: number } }>((acc, answer) => {
			acc[answer.questionCatalogName] = acc[answer.questionCatalogName] || { catalogName: answer.questionCatalogName, correctAnswers: 0, inCorrectAnswers: 0 };
			if (answer.isCorrect) {
				acc[answer.questionCatalogName].correctAnswers++;
			} else {
				acc[answer.questionCatalogName].inCorrectAnswers++;
			}
			return acc;
		}, {});
		return {
			uuid: user.uuid,
			email: user.email,
			catalogs: Object.values(catalogs),
		};
	}).filter((user) => user !== null);

	const [checkedCatalogs, setCheckedCatalogs] = useState<Record<string, Record<string, boolean>>>({}); 

	const handleCheckboxChange = (userUuid: string, catalogName: string) => {
		setCheckedCatalogs(prevState => {
			const userCatalogs = {...(prevState[userUuid] || {})};
			userCatalogs[catalogName] = !userCatalogs[catalogName];
	
			return {
				...prevState,
				[userUuid]: userCatalogs
			};
		});
	};

	const _accordionChangeHandler = (uuid: string) => {
		if (expanded === uuid) {
			setExpanded(false);
		} else {
			setExpanded(uuid);
		}
	};

	return (
		<TableContainer component={Paper} sx={{ maxWidth: 900, margin: 'auto', marginTop: 10 }}>
			<Table aria-label="simple table">
				<TableHead>
				<StyledTableRow className='header'>
					<TableCell align="left">E-MAIL</TableCell>
					<TableCell align="right">onRetail-Module (orange = Schulung empfohlen)</TableCell>
				</StyledTableRow>
				</TableHead>
				<TableBody>
				{users?.map((row) => (
					<StyledTableRow key={row?.uuid}>
						<TableCell align="left">
							{row?.email}
						</TableCell>
						<TableCell align="right">
							<StyledAccordion expanded={ expanded === row?.uuid }>
								<StyledAccordionSummary
									aria-controls="panel1d-content"
									id="panel1d-header"
									onClick={() => _accordionChangeHandler(row?.uuid ?? "1")}
								>
									Schulungsempfehlung
								</StyledAccordionSummary>
								<StyledAccordionDetails>
									{row?.catalogs?.map((catalog) => (
										<StyledAccordionDetailsWrapper key={catalog.catalogName} onClick={() => handleCheckboxChange(row?.uuid ?? "1", catalog.catalogName)}>
											{catalog.catalogName}
											<StyledAnswerCaluculation className={catalog.inCorrectAnswers / (catalog.correctAnswers + catalog.inCorrectAnswers) >= 0.3 ? 'orange' : 'green'}>
												{catalog.correctAnswers} / {catalog.correctAnswers + catalog.inCorrectAnswers}
											</StyledAnswerCaluculation>
											<Checkbox
												edge="start"
												checked={checkedCatalogs[row?.uuid]?.[catalog.catalogName] || false}
												tabIndex={-1}
												disableRipple
												sx = {{marginLeft: '10px'}}
												inputProps={{ 'aria-labelledby': catalog.catalogName }}
											/>
										</StyledAccordionDetailsWrapper>
									))}
								</StyledAccordionDetails>
							</StyledAccordion>
						</TableCell>
					</StyledTableRow>
					)
				)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default ConfigCheck(QuestionsPage, [[CONFIG_MAP.questions]]);