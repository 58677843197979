import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//actions
import CoreActions from 'Redux/Core.actions';

//selectors
import CoreSelectors from 'Redux/Core.selectors';

//styles
import {
	StyledDialog,
	StyledDialogTitle,
	StyledDialogContent,
	StyledDialogContentText,
	StyledDialogActions,
	StyledDialogButton,
} from './styles/AlertDialog.style';

const AlertDialog: FC = () => {
	const dispatch = useDispatch();

	const alertDialogProps = useSelector(CoreSelectors.getAlertDialogProps);

	if (!alertDialogProps) return null;

	const {
		message,
		type = 'error',
		title = 'Error',
		succesButtonLabel = 'Bestätigen',
	} = alertDialogProps;

	const handleClose = () => {
		dispatch(CoreActions.DISPLAY_ERROR.action(null));
	};

	return (
		<StyledDialog
			open={Boolean(alertDialogProps)}
			onClose={handleClose}
			fullWidth={true}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<StyledDialogTitle id="alert-dialog-title" className={type}>
				{title}
			</StyledDialogTitle>
			<StyledDialogContent>
				<StyledDialogContentText id="alert-dialog-description">
					{message}
				</StyledDialogContentText>
			</StyledDialogContent>
			<StyledDialogActions>
				<StyledDialogButton onClick={handleClose} autoFocus>
					{succesButtonLabel}
				</StyledDialogButton>
			</StyledDialogActions>
		</StyledDialog>
	);
}

export default AlertDialog;
