import AdminpanelActions from "./Adminpanel.actions";

export interface StateAdminpanel {
	isLoading: boolean;
	users: UserReduxType[] | null;
}

export const defaultState: StateAdminpanel = {
	isLoading: false,
	users: null,
};

type AdminpanelActionTypes = ReturnType<typeof AdminpanelActions.SET_IS_LOADING.action>
	| ReturnType<typeof AdminpanelActions.SET_USERS.action>;

export default (
	state: StateAdminpanel = defaultState,
	action: AdminpanelActionTypes,
): StateAdminpanel => {
	switch (action.type) {
		case AdminpanelActions.SET_IS_LOADING.ACTION:
			return {
				...state,
				isLoading: action.payload,
			};
			
		case AdminpanelActions.SET_USERS.ACTION:
			return {
				...state,
				users: action.payload,
			};

		default:
			return state;
	}
};
