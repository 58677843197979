//utils
import { createAction as createReduxAction } from "Utils/Redux";

const createAction = <T extends string, P = void>(type: T) => createReduxAction<
	T, P, "CORE"
>(type, "CORE");

/*const createRoutine = <
	T extends string,
	TriggerPayload = void,
	RequestPayload = void,
	SuccessPayload = void,
	FailurePayload = void,
	FulfillPayload = void
>(type: T) => createReduxRoutine<
	T,
	TriggerPayload,
	RequestPayload,
	SuccessPayload,
	FailurePayload,
	FulfillPayload,
	"CORE"
>(type, "CORE");*/

export default {
	//INITIATE: createAction("INITIATE"),
	//UNINITIATE: createAction("UNINITIATE"),
	//TEST_ACTION: createAction<"TEST_ACTION", string>("TEST_ACTION"),
	//FETCH_CONFIG: createAction("FETCH_CONFIG"),
	SET_CONFIG: createAction<"SET_CONFIG", Record<string, boolean>>("SET_CONFIG"),
	UPDATE_CONFIG: createAction<"UPDATE_CONFIG", Record<string, boolean>>("UPDATE_CONFIG"),
	SET_ROUTE: createAction<"SET_ROUTE", string>("SET_ROUTE"),
	DISPLAY_ERROR: createAction<"DISPLAY_ERROR", AlertDialogReduxType | null>("DISPLAY_ERROR"),
};
