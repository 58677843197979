import React from 'react';
import './App.css';
import {
	BrowserRouter as Router,
} from "react-router-dom";
import MainApp from "Components/MainApp";

//components
import ConfigTracker from "Helper/ConfigTracker";
import RouteTracker from 'Helper/RouteTracker';

function App(): JSX.Element {
	return (
	<Router>
		<MainApp />
		<RouteTracker />
		<ConfigTracker />
	</Router>
	);
}

export default App;
