import React, { FC, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

//components
import AlertDialog from "Components/Shared/AlertDialog";
import AdminpanelPage from "Containers/Adminpanel/UI/AdminpanelPage/AdminpanelPage";
import Navbar from "Components/Navbar";
import LoginSite from "Containers/Auth/UI/LoginSite/LoginSite";
import RegisterSite from "Containers/Auth/UI/RegisterSite/RegisterSite";
import QuestionsPage from "Containers/Questions/UI/QuestionsPage/QuestionsPage";

//hooks
import useGetConfig from "Hooks/General/useGetConfig";

//HOC
import { CONFIG_MAP } from "Constants/Config";

//styles
import { MainAppWrapper } from "Components/styles/MainApp.styles";

const MainApp: FC = () => {
	const config = useGetConfig();
	const isAuthenticated = config.isAuthenticated;

	const [marginTop, setMarginTop] = useState("7vh");

	useEffect(() => {
		const checkViewportSizes = () => {
			const vw = window.innerWidth * 0.04;
			const vh = window.innerHeight * 0.07;
			setMarginTop(vw > vh ? "4vw" : "7vh");
		};

		checkViewportSizes();
		window.addEventListener("resize", checkViewportSizes);

		return () => window.removeEventListener("resize", checkViewportSizes);
	}, []);

	const defaultRouteComponent = (() => {
		if (config[CONFIG_MAP.adminpanel]) return <AdminpanelPage />;
		if (config[CONFIG_MAP.questions]) return <QuestionsPage />;
		return <QuestionsPage />;
	})();

	const routes = isAuthenticated
		? [
			<Route path="/" element={defaultRouteComponent} key="Questions" />,
			<Route path="/login" element={<QuestionsPage/>} key="Questions" />,
			<Route path="/register" element={<QuestionsPage/>} key="Questions" />,
			<Route path="/user" element={<LoginSite/>} key="User" />,
			<Route path="/adminpanel" element={<AdminpanelPage/>} key="Adminpanel" />,
			<Route path="/questions" element={<QuestionsPage/>} key="Questions" />,
		]
		: [
			<Route path="/" element={<LoginSite/>} key="Login" />,
			<Route path="/login" element={<LoginSite/>} key="Login" />,
			<Route path="/register" element={<RegisterSite/>} key="Register" />,
			<Route path="/user" element={<LoginSite/>} key="Login" />,
			<Route path="/adminpanel" element={<LoginSite/>} key="Login" />,
			<Route path="/questions" element={<LoginSite/>} key="Login" />,
		];

	return (
		<>
			<Navbar />
			<MainAppWrapper marginTop={marginTop}>
				<Routes>
					{routes}
				</Routes>
			</MainAppWrapper>
			<AlertDialog />
		</>
	);
};

export default React.memo(MainApp);
