import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//selector
import { getCoreRouterLocation } from 'Redux/Core.selectors';

export const RouteTracker: FC = () => {
	const routerLocation = useSelector(getCoreRouterLocation);
	const navigate = useNavigate();

	useEffect(() => {
		navigate(routerLocation);
	}, [routerLocation]);

	return null;
}

export default RouteTracker;
