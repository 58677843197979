//utils
import { Request } from "Utils/axiosRequest";

type LoginRequestResponse = {
	message: string;
	data: {
		uuid: string;
		token: string;
		role: "admin" | "employee" | "user";
		permissions: PermissionReduxType[];
	};
} | {
	error: string;
};

export const loginRequest = async (
	email: string,
	password: string,
): Promise<LoginRequestResponse> => {
	const response = await Request.toApi({
		url: "api/auth/login.php",
		method: "POST",
		data: {
			email,
			password,
		},
	});

	return response;
};

export const registerRequest = async (
	email: string,
	password: string,
): Promise<{ message: string }> => {
	const response = await Request.toApi({
		url: "api/auth/register.php",
		method: "POST",
		data: {
			email,
			password,
		},
	});

	return response;
}

export const logoutRequest = async (token: string): Promise<{ message: string }> => {
	const response = await Request.toApi({
		url: "api/auth/logout.php",
		method: "POST",
		token,
	});

	return response;
};
