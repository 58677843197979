import { put, call, takeLatest, select, take } from "redux-saga/effects";
import { AsyncReturnType } from "type-fest";

//actions
import AdminpanelActions from "Containers/Adminpanel/Adminpanel.actions";

//selectors
import AuthSelectors from "Containers/Auth/Auth.selectors";

//utils
import { getAllUsers, setPermission, setRole } from "./Adminpanel.utils";

function* onInitiate() {
	yield take((action: any) => action.type === "persist/REHYDRATE" && action.key === "root");
	console.log("onInitiate - Adminpanel");
	yield put(AdminpanelActions.FETCH_USERS.action());
}

function* onUninitiate() {
	console.log("onUninitiate");
}

function* onFetchUsers() {
	yield put(AdminpanelActions.SET_IS_LOADING.action(true));
	const token: ReturnType<typeof AuthSelectors.getToken> = yield select(AuthSelectors.getToken);
	if (token) {
		try {
			const response: AsyncReturnType<typeof getAllUsers> = yield call(getAllUsers, token);
			if ("data" in response) {
				yield put(AdminpanelActions.SET_USERS.action(response.data));
			}
		} catch (error) {
			console.error(error);
		}
	}
	yield put(AdminpanelActions.SET_IS_LOADING.action(false));
}

function* onSetRole({ payload }: ReturnType<typeof AdminpanelActions.SET_ROLE.action>) {
	yield put(AdminpanelActions.SET_IS_LOADING.action(true));
	const { userUuid, role } = payload;
	const token: ReturnType<typeof AuthSelectors.getToken> = yield select(AuthSelectors.getToken);
	if (token) {
		try {
			yield call(setRole, token, {
				uuid: userUuid,
				role,
			});
		} catch (error) {
			console.error(error);
		} finally {
			yield put(AdminpanelActions.FETCH_USERS.action());
		}
	}
	yield put(AdminpanelActions.SET_IS_LOADING.action(false));
}

function* onSetPermission({ payload }: ReturnType<typeof AdminpanelActions.SET_PERMISSION.action>) {
	yield put(AdminpanelActions.SET_IS_LOADING.action(true));
	const { userUuid, moduleUuid, isActive } = payload;
	const token: ReturnType<typeof AuthSelectors.getToken> = yield select(AuthSelectors.getToken);
	if (token) {
		try {
			yield call(setPermission, token, {
				uuid: userUuid,
				permission: moduleUuid,
				state: isActive,
			});
		} catch (error) {
			console.error(error);
			yield put(AdminpanelActions.SET_IS_LOADING.action(false));
		} finally {
			yield put(AdminpanelActions.FETCH_USERS.action());
		}
	}
}

export default function* rootSagas() {
	yield takeLatest(AdminpanelActions.INITIATE.ACTION, onInitiate);
	yield takeLatest(AdminpanelActions.UNINITIATE.ACTION, onUninitiate);
	yield takeLatest(AdminpanelActions.FETCH_USERS.ACTION, onFetchUsers);
	yield takeLatest(AdminpanelActions.SET_ROLE.ACTION, onSetRole);
	yield takeLatest(AdminpanelActions.SET_PERMISSION.ACTION, onSetPermission);
}
