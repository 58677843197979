import {
	configureStore,
	combineReducers,
	Reducer,
	Middleware,
} from '@reduxjs/toolkit';
import createSagaMiddleware, { Saga, Task } from 'redux-saga';
import { persistStore, persistReducer, createMigrate, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import localForage from "localforage";
//import { persistReducer, createMigrate } from "redux-persist";
//import storage from 'redux-persist/lib/storage';
import { all, fork } from 'redux-saga/effects';

//config
import { CONFIG_MAP } from "Constants/Config";

//Modules
import { initializeAuth } from 'Containers/Auth/Auth.config';
import { initializeAdminpanel } from 'Containers/Adminpanel/Adminpanel.config';
import { initializeQuestions } from 'Containers/Questions/Questions.config';

//Core
import CoreReducer from './Core.reducer';
import CoreSagas from './Core.sagas';

interface InjectedReducers {
	[key: string]: Reducer;
}

/*interface InjectedSagas {
	[key: string]: Saga;
}*/

const persistConfig = {
	key: "root",
	storage: localForage.createInstance({ name: "Approot" }),
	timeout: null as unknown as undefined,
	blacklist: ['Core'], //TEMP - maybe we need to persist and flush the modules after injection to ensure the updated state is saved
	version: 0,
	migrate: createMigrate({
		0: (state) => state,
	}),
};

interface Module {
	id: string;
	reducerMap?: Record<string, Reducer>;
	sagas?: Saga[];
	initialActions?: any[];
	finalActions?: any[];
}

export interface EnhancedStore extends ReturnType<typeof configureStore> {
	asyncReducers: InjectedReducers;
	injectReducer: (key: string, reducer: Reducer) => void;
	injectSaga: (key: string, saga: Saga) => void;
	initiatedModules: Module[];
}

const createRootReducer = (asyncReducers: InjectedReducers) => {
	const reducerMap = {
		Core: CoreReducer,
		...Object.keys(asyncReducers).reduce((acc: Record<string, any>, key) => {
			if (store.initiatedModules.find((m) => m.id === key)) {
				acc[key] = asyncReducers[key];
			}
			return acc;
		}, {}),
	};

	const persistedReducer = persistReducer(persistConfig, combineReducers(reducerMap));

	return persistedReducer;
};

const sagaMiddleware = createSagaMiddleware();

const configureAppStore = (initialState = {}) => {
	const asyncReducers: InjectedReducers = {};

	const baseStore = configureStore({
		reducer: createRootReducer(asyncReducers),
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(sagaMiddleware),
		preloadedState: initialState,
		devTools: process.env.NODE_ENV !== 'production',
	});

	const sagas: Record<string, Task<any>> = {};

	const store = {
		...baseStore,
		asyncReducers,
		initiatedModules: [] as Module[],
		injectReducer: (key: string, reducer: Reducer<any, any>) => {
			if (store.asyncReducers[key]) return;
			store.asyncReducers[key] = reducer;
			store.replaceReducer(createRootReducer(store.asyncReducers));
		},
		injectSaga: (key: string, saga: Saga) => {
			if (!sagas[key]) {
				const sagaTask = sagaMiddleware.run(saga);
				sagas[key] = sagaTask;
			}
		},
		removeReducer: (key: string) => {
			if (!store.asyncReducers[key]) return;
			delete store.asyncReducers[key];
			store.replaceReducer(createRootReducer(store.asyncReducers));
		},
		cancelSaga: (key: string) => {
			const sagaTask = sagas[key];
			if (sagaTask) {
				sagaTask.cancel();
				delete sagas[key];
			}
		}
	}

	const persistor = persistStore(store);

	return { store, persistor };
}

function* rootSaga() {
	yield all([
		CoreSagas(),
	]);
}

export async function initializeStore() {
	const config = store.getState().Core.config;

	const featureModules = [
		initializeAuth(config),
		initializeAdminpanel(config),
		initializeQuestions(config),
	].flat();

	for (const module of featureModules) {
		const foundModule = store.initiatedModules.find((m) => m.id === module.id);
		if (!foundModule) {
			store.initiatedModules.push(module);
			if (module.reducerMap) {
				for (const key in module.reducerMap) {
					store.injectReducer(key, module.reducerMap[key]);
					persistor.persist();
					await persistor.flush();
				}
			}

			if (module.sagas) {
				module.sagas.forEach((saga: any) => {
					store.injectSaga(module.id, saga);
				});
			}

			if (module.initialActions) {
				module.initialActions.forEach((action: any) => {
					store.dispatch(action);
				});
			}
		}
	}
	for (const module of store.initiatedModules) {
		const foundModule = featureModules.find((m) => m.id === module.id);
		if (!foundModule && module.finalActions) {
			module.finalActions.forEach((action: any) => {
				store.dispatch(action);
			});
		}
		if (!foundModule) {
			store.initiatedModules = store.initiatedModules.filter((m) => m.id !== module.id);
			if (module.reducerMap) {
				for (const key in module.reducerMap) {
					store.removeReducer(key);
					persistor.persist();
					await persistor.flush();
				}
			}
			if (module.sagas) {
				store.cancelSaga(module.id);
			}
		}
	}
	store.dispatch({ type: 'persist/REHYDRATE', key: 'root' });
}

const { store, persistor } = configureAppStore();
sagaMiddleware.run(rootSaga);

export { store, persistor };

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
