import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, FormControlLabel, FormGroup, Button, Paper } from '@mui/material';

//actions
import QuestionsActions from 'Containers/Questions/Questions.actions';

//components
import LoadingScreen from 'Components/Shared/LoadingScreen';

//selectors
import {
    getQuestionsCatalogs,
    getQuestionsIsLoading
} from 'Containers/Questions/Questions.selectors';

//HOC
import ConfigCheck from 'HOC/ConfigCheck';

//constants
import { CONFIG_MAP } from 'Constants/Config';


interface CatalogSelectionState {
    [key: number]: boolean;
}

const QuestionsCatalogSelector: React.FC = () => {
    const dispatch = useDispatch();
    const catalogs = useSelector(getQuestionsCatalogs);
    const isLoading: boolean = useSelector(getQuestionsIsLoading);
    const [selectedCatalogs, setSelectedCatalogs] = useState<CatalogSelectionState>({});

    if (!catalogs?.length) return <LoadingScreen />;

    const handleChange = (catalogId: number) => {
        setSelectedCatalogs(prev => ({
            ...prev,
            [catalogId]: !prev[catalogId]
        }));
    };

    const handleSubmit = () => {
        dispatch(QuestionsActions.UPSERT_CATALOGS.request(selectedCatalogs));
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <Paper sx={{ padding: 3, margin: 'auto', marginTop: 2, maxWidth: 600 }}>
			<h3>Bitte wählen Sie Ihre gebuchten Module aus:</h3>
            <FormGroup>
                {catalogs.map(catalog => (
                    <FormControlLabel
                        key={catalog.id}
                        control={
                            <Checkbox
                                checked={!!selectedCatalogs[catalog.id]}
                                onChange={() => handleChange(catalog.id)}
                            />
                        }
                        label={catalog.name}
                    />
                ))}
            </FormGroup>
            <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ marginTop: "13px" }}>
                Speichern
            </Button>
        </Paper>
    );
};

export default ConfigCheck(QuestionsCatalogSelector, [[CONFIG_MAP.questions]]);