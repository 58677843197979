import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

//actions
import AdminpanelActions from 'Containers/Adminpanel/Adminpanel.actions';
import CoreActions from 'Redux/Core.actions';

//HOC
import ConfigCheck from 'HOC/ConfigCheck';

//components
import ModalSelect from 'Components/Shared/ModalSelect';

//constants
import { CONFIG_MAP } from 'Constants/Config';
import { USER_ROLE } from 'Containers/Adminpanel/Adminpanel.constants';
import { ERROR_MESSAGE } from 'Constants/ErrorMessage';

//selectors
import AuthSelectors from 'Containers/Auth/Auth.selectors';
import { getAdminpanelIsLoading, getAdminpanelUsers } from 'Containers/Adminpanel/Adminpanel.selectors';

//style
import { StyledButton, LoadingTableCell, LoadingTableRow, Gradient, StyledTableRow } from './AdminpanelPage.style';

const AdminpanelPage: FC = () => {
	const dispatch = useDispatch();
	const isLoading = useSelector(getAdminpanelIsLoading);
	const users = useSelector(getAdminpanelUsers);
	const actualUserUuid = useSelector(AuthSelectors.getUuid);

	const [selectedUser, setSelectedUser] = useState("")
	const [isSelectRoleOpen, setIsSelectRoleOpen] = useState(false);

	const _onSetRoleInit = (userUuid: string) => () => {
		setSelectedUser(userUuid);
		if (userUuid === actualUserUuid) {
			dispatch(CoreActions.DISPLAY_ERROR.action({ message: ERROR_MESSAGE.EDIT_OWN_ROLE_WARNING }));
		}
		setIsSelectRoleOpen(true);
	};

	const _onSetRoleFinish = (role: RoleReduxType) => () => {
		setIsSelectRoleOpen(false);
		dispatch(AdminpanelActions.SET_ROLE.action({ userUuid: selectedUser, role }));
	};

	const _onSetPermission = (userUuid: string, moduleUuid: string, isActive: boolean) => () => {
		dispatch(AdminpanelActions.SET_PERMISSION.action({ userUuid, moduleUuid, isActive }));
	};

	return (
		<TableContainer component={Paper} sx={{ maxWidth: 900, margin: 'auto', marginTop: 10 }}>
			<Table aria-label="simple table">
				<TableHead>
				<StyledTableRow className='header'>
					<TableCell align="left">E-Mail</TableCell>
					<TableCell align="center">Role</TableCell>
					<TableCell align="right">Permissions</TableCell>
				</StyledTableRow>
				</TableHead>
				<TableBody>
				{users?.map((row) => (
					isLoading ? (
						<TableLoadingPlaceholder key={row.uuid}/>
					) : (
					<StyledTableRow key={row.uuid}>
						<TableCell align="left">
							{isLoading ? <CircularProgress size={20} /> : row.email}
						</TableCell>
						<TableCell align="center">
							{isLoading ? <CircularProgress size={20} /> : (
								<StyledButton
									className={row.role === 'admin' ? 'green' : row.role === 'employee' ? 'blue' : 'lightblue'}
									size="small"
									variant="contained"
									onClick={_onSetRoleInit(row.uuid)}
								>
									{row.role === 'user' ? 'Customer' : row.role}
								</StyledButton>
							)}
						</TableCell>
						<TableCell align="right">
							{isLoading ? (
								 <CircularProgress size={20} />
							) : (
							row.permissions.map((module) => (
								<StyledButton
									key={module.uuid}
									className={module.isActive ? 'green' : 'orange'}
									size="small"
									variant="contained"
									onClick={_onSetPermission(row.uuid, module.uuid, !module.isActive)}
								>
									{module.name}
								</StyledButton>
							))
						)}
						</TableCell>
					</StyledTableRow>
					)
				))}
				</TableBody>
			</Table>
			<ModalSelect
				title="Select Role"
				items={[
					{ label: "Admin", value: USER_ROLE.ADMIN },
					{ label: "Employee", value: USER_ROLE.EMPLOYEE },
					{ label: "User", value: USER_ROLE.USER },
				]}
				isOpen={isSelectRoleOpen}
				onClose={() => setIsSelectRoleOpen(false)}
				onSelect={(selectedRole) => {
					_onSetRoleFinish(selectedRole.value)();
				}}
			/>
		</TableContainer>
	);
}

const TableLoadingPlaceholder: FC = () => {
	return (
	  <LoadingTableRow>
		<LoadingTableCell><Gradient /></LoadingTableCell>
		<LoadingTableCell><Gradient /></LoadingTableCell>
		<LoadingTableCell><Gradient /></LoadingTableCell>
		<LoadingTableCell><Gradient /></LoadingTableCell>
	  </LoadingTableRow>
	);
  };

export default ConfigCheck(AdminpanelPage, [[CONFIG_MAP.adminpanel]]);