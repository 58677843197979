import type { EnhancedStore } from "Redux/store";

class Cli {
	constructor(private readonly store: EnhancedStore) {
		this.store = store;

		console.log("Console is operational, type 'app' to see options");
	}

	state(): any {
		return this.store.getState();
	}
}

export default Cli;
