import { createAction as createReduxAction, createRoutine as createReduxRoutine } from "Utils/Redux";

const createAction = <T extends string, P = void>(type: T) => createReduxAction<
	T, P, "ADMIN_PANEL"
>(type, "ADMIN_PANEL");

const createRoutine = <
	T extends string,
	TriggerPayload = void,
	RequestPayload = void,
	SuccessPayload = void,
	FailurePayload = void,
	FulfillPayload = void
>(type: T) => createReduxRoutine<
	T,
	TriggerPayload,
	RequestPayload,
	SuccessPayload,
	FailurePayload,
	FulfillPayload,
	"ADMIN_PANEL"
>(type, "ADMIN_PANEL");

export default {
	INITIATE: createAction("INITIATE"),
	UNINITIATE: createAction("UNINITIATE"),
	SET_IS_LOADING: createAction<"SET_IS_LOADING", boolean>("SET_IS_LOADING"),
	FETCH_USERS: createAction("FETCH_USERS"),
	SET_USERS: createAction<"SET_USERS", UserReduxType[]>("SET_USERS"),
	SET_ROLE: createAction<"SET_ROLE", { userUuid: string, role: RoleReduxType }>("SET_ROLE"),
	SET_PERMISSION: createAction<"SET_PERMISSION", { userUuid: string, moduleUuid: string, isActive: boolean }>("SET_PERMISSION"),
};
