import axios from "axios";

const toApi = async (
	options: {
		url: string,
		method: "GET" | "POST" | "PUT" | "DELETE",
		token?: string,
		data?: any
	}
) => {
    try {
		const { url, method, token, data } = options;
        const response = await axios({
            method,
            url: `https://api.diagtool-etron.at/${url}`,
			headers: {
				'Content-Type': 'application/json',
				'Accept': '*/*',
				...(token && { 'Authorization': `Bearer ${token}` }),
			},
			...(data && { data }),
        });
        return response.data;
    } catch (error: any) {
        console.error(error);
		if ("response" in error && "data" in error.response) return error.response.data;
    }
}

export const Request = {
	toApi,
};
