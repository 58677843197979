//utils
import { createAction as createReduxAction, createRoutine as createReduxRoutine } from "Utils/Redux";

const createAction = <T extends string, P = void>(type: T) => createReduxAction<
	T, P, "AUTH"
>(type, "AUTH");

const createRoutine = <
	T extends string,
	TriggerPayload = void,
	RequestPayload = void,
	SuccessPayload = void,
	FailurePayload = void,
	FulfillPayload = void
>(type: T) => createReduxRoutine<
	T,
	TriggerPayload,
	RequestPayload,
	SuccessPayload,
	FailurePayload,
	FulfillPayload,
	"AUTH"
>(type, "AUTH");

export default {
	INITIATE: createAction("INITIATE"),
	UNINITIATE: createAction("UNINITIATE"),
	//TEST_ACTION: createAction<"TEST_ACTION", string>("TEST_ACTION"),
	SET_IS_LOADING: createAction<"SET_IS_LOADING", boolean>("SET_IS_LOADING"),
	LOGIN: createRoutine<"LOGIN",
		void,
		{ email: string, password: string },
		{ uuid: string, token: string, role: "admin" | "employee" | "user", permissions: PermissionReduxType[] },
		{ error: string }
	>("LOGIN"),
	REGISTER: createRoutine<"REGISTER",
		void,
		{ email: string, password: string },
		//{ uuid: string, token: string, role: "admin" | "employee" | "user", permissions: string[] },
		void,
		{ error: string }
	>("REGISTER"),
	LOGOUT: createRoutine<"LOGOUT",
		void,
		void,
		void,
		{ error: string }
	>("LOGOUT"),
	SET_USER_ROLE: createAction<"SET_USER_ROLE", "admin" | "employee" | "user">("SET_USER_ROLE"),
	SET_USER_PERMISSIONS: createAction<"SET_USER_PERMISSIONS", PermissionReduxType[]>("SET_USER_PERMISSIONS"),
};
