//types
import { AppState } from "Redux/definitions/AppState";
import { defaultState } from "./Core.reducer";

type CompleteState = AppState;

export const getCore = (state: CompleteState) => state.Core || defaultState;

export const getCoreConfig = (state: CompleteState) => getCore(state).config;
export const getCoreRouterLocation = (state: CompleteState) => getCore(state).routerLocation;
export const getCoreAlertDialogProps = (state: CompleteState) => getCore(state).alertDialogProps;

export default {
	getConfig: getCoreConfig,
	getAlertDialogProps: getCoreAlertDialogProps,
};
