import React, { FC } from 'react';
import { useSelector } from 'react-redux';

//HOC
import ConfigCheck from 'HOC/ConfigCheck';

//components
import LoadingScreen from 'Components/Shared/LoadingScreen';
import QuestionsPageUser from '../QuestionsPageUser/QuestionsPageUser';
import QuestionsPageAdmin from '../QuestionsPageAdmin/QuestionsPageAdmin';

//constants
import { CONFIG_MAP } from 'Constants/Config';

//selectors
import AuthSelectors from 'Containers/Auth/Auth.selectors';
import { getQuestionsIsLoading } from 'Containers/Questions/Questions.selectors';

const QuestionsPage: FC = () => {
	const isLoading = useSelector(getQuestionsIsLoading);
	const role = useSelector(AuthSelectors.getRole);

	if (isLoading) return <LoadingScreen />;
	if (role === 'admin' || role === 'employee') {
		return <QuestionsPageAdmin />;
	}
	return <QuestionsPageUser />;
}

export default ConfigCheck(QuestionsPage, [[CONFIG_MAP.questions]]);