import { styled, Box, Accordion, AccordionSummary, AccordionDetails, TableRow } from "@mui/material";

export const StyledAccordion = styled(Accordion)({
	backgroundColor: "transparent",
	boxShadow: "none",
	"&::before": {
		display: "none",
	},
	"&.Mui-expanded": {
		margin: "auto",
	},
});

export const StyledAccordionSummary = styled(AccordionSummary)({
	float: "right",
	border: "2px solid #8a8888",
	borderRadius: "5px",
	padding: "0px 50px",
	"&.Mui-expanded": {
		minHeight: "auto",
		margin: "0px",
	},
});

export const StyledAccordionDetails = styled(AccordionDetails)({
	display: "flex",
	flexDirection: "column",
	gap: "10px",
	padding: "15px 10px 10px 10px",
	width: "auto",
});

export const StyledAccordionDetailsWrapper = styled(Box)({
	display: "flex",
	flexDirection: "row", 
	justifyContent: "flex-end",
	alignItems: "center",
});

export const StyledAnswerCaluculation = styled(Box)({
	marginLeft: '10px',
	color: 'white',
	padding: '0 4px',
	width: 'fit-content',
	right: '0',
	"&.orange": {
		backgroundColor: 'orange',
		color: 'black',
	},
	"&.green": {
		backgroundColor: 'limegreen',
	},
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: "white",
	},
	'&:nth-of-type(even)': {
		backgroundColor: "#f2f2f2",
	},
	"&.header": {
		backgroundColor: "#1976d2",
		"& th": {
			fontWeight: "bold",
			color: "white",
		},
	},
}));
