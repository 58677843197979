import { Request } from "Utils/axiosRequest";

type GetUserRequestResponse = {
	message: string;
	data: any[];
} | {
	error: string;
};

export const getAllUsers = async (token: string): Promise<GetUserRequestResponse> => {
	const response = await Request.toApi({
		url: "api/user/",
		method: "GET",
		token,
	});

	return response;
};

export const setPermission = async (
	token: string,
	payload: { uuid: string, permission: string, state: boolean }
): Promise<GetUserRequestResponse> => {
	const response = await Request.toApi({
		url: "api/user/permission.php",
		method: "POST",
		token,
		data: payload,
	});

	return response;
}

export const setRole = async (
	token: string,
	payload: { uuid: string, role: string }
): Promise<GetUserRequestResponse> => {
	const response = await Request.toApi({
		url: "api/user/role.php",
		method: "POST",
		token,
		data: payload,
	});

	return response;
}