import { useSelector } from "react-redux";

//constants
import { CONFIG_MAP } from "Constants/Config";

//selectors
import AuthSelectors from "Containers/Auth/Auth.selectors";

export default (): Record<keyof typeof CONFIG_MAP | "isAuthenticated", boolean> => {
	const token = useSelector(AuthSelectors.getToken);
	const role = useSelector(AuthSelectors.getRole);
	const permissions = useSelector(AuthSelectors.getPermissions);

	const questions = Boolean(permissions?.find((permission) => permission.name === "questions" && permission.isActive));

	const isAdmin = role === "admin";

	const isAuthenticated = Boolean(token);

	const config = {
		[CONFIG_MAP.adminpanel]: isAdmin,
		[CONFIG_MAP.questions]: questions,
		isAuthenticated,
	};
	return config;
};
