export const ERROR_MESSAGE = {
	INVALID_EMAIL_OR_PASSWORD: "Ungültige Emailadresse oder Passwort",
	INVALID_EMAIL: "Ungültige Emailadresse",
	LOGIN_SUCCESS: "Login erfolgreich",
	MISSING_FIELDS: "Bitte füllen Sie alle Felder aus",
	PASSWORDS_DO_NOT_MATCH: "Passwörter stimmen nicht überein",
	REGISTRATION_SUCCESS: "Registrierung erfolgreich",
	USER_LOGGED_OUT: "Abmeldung erfolgreich",
	EDIT_OWN_ROLE_WARNING: "Achtung! Sie bearbeiten Ihre eigene Rolle.",
} as const;
