import { styled, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

export const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        borderRadius: "6px",
        boxShadow: `0 3px 10px grey`,
        maxWidth: "400px",
    },
});

export const StyledDialogTitle = styled(DialogTitle)({
    backgroundColor: "#6a1b9a",
    color: "#fff",
    padding: "16px 24px",
    fontSize: "1.25rem",
    fontWeight: "bold",
    borderRadius: "6px 6px 0 0",
	"&.error": {
		backgroundColor: "red",
	},
	"&.info": {
		backgroundColor: "lightskyblue",
		color: "black",
	},
	"&.success": {
		backgroundColor: "limegreen",
	},
	"&.warning": {
		backgroundColor: "orange",
	},
});

export const StyledDialogContent = styled(DialogContent)({
    backgroundColor: "#fff",
    padding: "20px 24px !important",
    fontSize: "1rem",
    color: "#444",
});

export const StyledDialogContentText = styled(DialogContentText)({
    margin: "0",
});

export const StyledDialogActions = styled(DialogActions)({
    backgroundColor: "#fff",
    padding: "0",
    borderRadius: "0",
	display: "flex",
	flexDirection: "column",
});

export const StyledDialogButton = styled(Button)({
	backgroundColor: "#1976d2",
    color: "#fff",
    fontWeight: "bold",
	padding: "15px 24px",
	width: "100%",
	borderRadius: "0",
	"&:hover": {
		backgroundColor: "#115293",
	},
});