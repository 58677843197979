import { AppState } from "Redux/definitions/AppState";
import { defaultState, StateQuestions} from "./Questions.reducer";

type CompleteState = AppState & { Questions: StateQuestions };

export const getQuestions = (state: CompleteState) => state.Questions || defaultState;

export const getQuestionsIsLoading = (state: CompleteState) => getQuestions(state).isLoading;
export const getQuestionsQuestions = (state: CompleteState) => getQuestions(state).quesions;
export const getQuestionsCatalogs = (state: CompleteState) => getQuestions(state).catalogs;
export const getQuestionsGivenAnswers = (state: CompleteState) => getQuestions(state).givenAnswers;
export const getQuestionsAlreadySelectedCatalogs = (state: CompleteState) => getQuestions(state).alreadySelectedCatalogs;
export const getQuestionsAlreadyAnsweredQuestions = (state: CompleteState) => getQuestions(state).alreadyAnsweredQuestions;

export default {
	//nothing
};
