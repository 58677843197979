import { AppState } from "Redux/definitions/AppState";
import { defaultState, StateAdminpanel} from "./Adminpanel.reducer";

type CompleteState = AppState & { Adminpanel: StateAdminpanel };

export const getAdminpanel = (state: CompleteState) => state.Adminpanel || defaultState;

export const getAdminpanelIsLoading = (state: CompleteState) => getAdminpanel(state).isLoading;
export const getAdminpanelUsers = (state: CompleteState) => getAdminpanel(state).users;

export default {
	//nothing
};
