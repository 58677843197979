import { Box, Button, styled, keyframes, TableRow } from "@mui/material";

export const Tags = styled(Box)(() => ({
	minHeight: "19px",
	display: "flex",
	gap: "3px",
	flexWrap: "wrap",
}));

const movingGradient = keyframes`
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
`;

export const Gradient = styled('span')(({ theme }) => ({
  display: 'block',
  height: '5vh',
  backgroundColor: theme.palette.action.selected,
  backgroundImage: 'linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%)',
  backgroundSize: '500px 100px',
  animation: `${movingGradient} 1s linear infinite`,
}));

export const LoadingTableCell = styled('td')({
  verticalAlign: 'middle',
  padding: '8px',
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent)',
    animation: `${movingGradient} 1s linear infinite`,
  },
});

export const LoadingTableRow = styled('tr')({
  borderBottom: '1px solid rgba(0,0,0,.1)',
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "white",
	},
	"&:nth-of-type(even)": {
		backgroundColor: "#f2f2f2",
	},
	"&.header": {
		backgroundColor: "#1976d2",
		"& th": {
			fontWeight: "bold",
			color: "white",
			textTransform: "uppercase",
		},
	},
}));

export const StyledButton = styled(Button)({
	"&.orange": {
		backgroundColor: "orange",
		color: 'black',
	},
	"&.green": {
		backgroundColor: "limegreen",
	},
	"&.blue": {
		backgroundColor: "blue",
	},
	"&.lightblue": {
		backgroundColor: "lightskyblue",
		color: "black",
	},
	"&:not(:first-of-type)": {
		marginLeft: "0.2vw",
	},
});
