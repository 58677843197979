import { createAction as createReduxAction, createRoutine as createReduxRoutine } from "Utils/Redux";

const createAction = <T extends string, P = void>(type: T) => createReduxAction<
	T, P, "QUESTIONS"
>(type, "QUESTIONS");

const createRoutine = <
	T extends string,
	TriggerPayload = void,
	RequestPayload = void,
	SuccessPayload = void,
	FailurePayload = void,
	FulfillPayload = void
>(type: T) => createReduxRoutine<
	T,
	TriggerPayload,
	RequestPayload,
	SuccessPayload,
	FailurePayload,
	FulfillPayload,
	"QUESTIONS"
>(type, "QUESTIONS");

export default {
	INITIATE: createAction("INITIATE"),
	UNINITIATE: createAction("UNINITIATE"),
	SET_IS_LOADING: createAction<"SET_IS_LOADING", boolean>("SET_IS_LOADING"),
	FETCH_USER_DATA: createAction("FETCH_USER_DATA"),
	SET_QUESTIONS: createAction<"SET_QUESTIONS", QuestionReduxType[]>("SET_QUESTIONS"),
	SET_CATALOGS: createAction<"SET_CATALOGS", QuestionCatalogReduxType[]>("SET_CATALOGS"),
	SET_GIVEN_ANSWERS: createAction<"SET_GIVEN_ANSWERS", Array<{
		uuid: string;
		email: string;
		answers: QuestionReduxType[];
	}> | QuestionReduxType[] | null>("SET_GIVEN_ANSWERS"),
	SET_ALREADY_SELECTED_CATALOGS: createAction<"SET_ALREADY_SELECTED_CATALOGS", boolean>("SET_ALREADY_SELECTED_CATALOGS"),
	SET_ALREADY_ANSWERED_QUESTIONS: createAction<"SET_ALREADY_ANSWERED_QUESTIONS", boolean>("SET_ALREADY_ANSWERED_QUESTIONS"),
	UPSERT_CATALOGS: createRoutine<"UPSERT_CATALOGS",
		void,
		{ [key: number]: boolean },
		void,
		string,
		void
	>("UPSERT_CATALOGS"),
	UPSERT_ANSWERS: createRoutine<"UPSERT_ANSWERS",
		void,
		{ [key: number]: number },
		void,
		string,
		void
	>("UPSERT_ANSWERS"),
};
