import React, { ComponentType } from "react";

//constants
import { CONFIG_MAP } from "Constants/Config";

//hooks
import useGetConfig from "Hooks/General/useGetConfig";

const ConfigCheck = (
	Component: ComponentType<any>,
	required: Array<Array<keyof typeof CONFIG_MAP>>,
	BackupComponent?: ComponentType<any>,
) => {
	const WrapperComponent = (props: any) => {
		const config = useGetConfig();
		for (const key of required) {
			if (key.length === 1) {
				if (!config[key[0]]) {
					return BackupComponent ? <BackupComponent {...props} /> : null;
				}
			} else {
				let found = false;
				for (const key2 of key) {
					if (config[key2]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return BackupComponent ? <BackupComponent {...props} /> : null;
				}
			}
		}
		return <Component {...props} />;
	};
	return WrapperComponent;
};

export default ConfigCheck;
