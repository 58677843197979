import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, FormControl, FormControlLabel, Radio, RadioGroup, Button, Typography } from '@mui/material';

//actions
import QuestionsActions from 'Containers/Questions/Questions.actions';

//HOC
import ConfigCheck from 'HOC/ConfigCheck';

//components
import QuestionsCatalogSelector from '../QuestionsCatalogSelector/QuestionsCatalogSelector';

//constants
import { CONFIG_MAP } from 'Constants/Config';

//selectors
import {
	getQuestionsAlreadyAnsweredQuestions,
	getQuestionsAlreadySelectedCatalogs,
	getQuestionsQuestions
} from 'Containers/Questions/Questions.selectors';

//style
import { FinishMessage } from './QuestionsPageUser.style';

const QuestionsPageUser: FC = () => {
	const dispatch = useDispatch();
	const alreadySelectedCatalogs = useSelector(getQuestionsAlreadySelectedCatalogs);
	const alreadyAnsweredQuestions = useSelector(getQuestionsAlreadyAnsweredQuestions);
	const questions = useSelector(getQuestionsQuestions);

	const [selectedAnswers, setSelectedAnswers] = useState<{ [questionId: number]: number }>({});

    const handleRadioChange = (questionId: number, answerId: number) => {
        setSelectedAnswers(prev => ({
            ...prev,
            [questionId]: answerId
        }));
    };

    const handleSubmit = () => {
		dispatch(QuestionsActions.UPSERT_ANSWERS.request(selectedAnswers));
    };

	if (!alreadySelectedCatalogs) {
		return <QuestionsCatalogSelector />;
	}

	if (alreadyAnsweredQuestions || !questions?.length) {
		return (
			<FinishMessage>
				Vielen Dank für Ihre Teilnahme!
			</FinishMessage>
		);
	}

	const groupedQuestionsByCatalog = questions.reduce<{ [key: number]: Array<{ questionId: number, questionName: string, catalogName: string, answers: Array<{ answerId: number, answerName: string, isCorrect: boolean }> }> }>((acc, question) => {
        acc[question.questionCatalogId] = acc[question.questionCatalogId] || [];
        let group = acc[question.questionCatalogId].find(g => g.questionId === question.questionId);
        if (!group) {
            group = {
                questionId: question.questionId,
                questionName: question.questionName,
				catalogName: question.questionCatalogName,
                answers: []
            };
            acc[question.questionCatalogId].push(group);
        }
        group.answers.push({
            answerId: question.answerId,
            answerName: question.answerName,
            isCorrect: question.isCorrect
        });
        return acc;
    }, {});

    return (
        <Paper sx={{ padding: 3, margin: 'auto', marginTop: 2, maxWidth: 600 }}>
            {Object.entries(groupedQuestionsByCatalog).map(([catalogId, catalogQuestions]) => (
                <div key={catalogId}>
                    <Typography variant="h4" gutterBottom>
                        {catalogQuestions[0].catalogName || `ID: ${catalogId}`}
                    </Typography>
                    {catalogQuestions.map(({ questionId, questionName, answers }) => (
                        <div key={questionId}>
                            <Typography variant="h6">{questionName}</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name={`question-${questionId}`}
                                    value={selectedAnswers[questionId] || ''}
                                    onChange={(e) => handleRadioChange(questionId, Number(e.target.value))}
                                >
                                    {answers.map(answer => (
                                        <FormControlLabel
                                            key={answer.answerId}
                                            value={answer.answerId}
                                            control={<Radio />}
                                            label={answer.answerName}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ))}
                </div>
            ))}
            <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ marginTop: "20px" }}>
                Antworten speichern
            </Button>
        </Paper>
    );
}

export default ConfigCheck(QuestionsPageUser, [[CONFIG_MAP.questions]]);