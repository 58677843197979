import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';
import HmacSHA256 from 'crypto-js/hmac-sha256';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

//actions
import AuthActions from "Containers/Auth/Auth.actions";
import CoreActions from 'Redux/Core.actions';

//constants
import { ERROR_MESSAGE } from 'Constants/ErrorMessage';

//slectors
import { getAuthIsLoading } from "Containers/Auth/Auth.selectors";

function Copyright(props: any) {
	return (
	<Typography variant="body2" color="text.secondary" align="center" {...props}>
		{'Copyright © '}
		<Link color="inherit" href="https://etron.at/">
		www.etron.at
		</Link>{' '}
		{new Date().getFullYear()}
		{'.'}
	</Typography>
	);
}

const RegisterSite: FC = () => {
	const dispatch = useDispatch();

	const isLoading = useSelector(getAuthIsLoading);

	const _handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const email = data.get('email') as string;
		const password = data.get('password') as string;
		const password2 = data.get('password2') as string;
		const hasedPassword = HmacSHA256(password, "liasjdfkjhasdf").toString();
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		if(!emailPattern.test(email)) {
			dispatch(CoreActions.DISPLAY_ERROR.action({ message: ERROR_MESSAGE.INVALID_EMAIL, type: "warning" }));
			return;
		}
		if(password !== password2) {
			dispatch(CoreActions.DISPLAY_ERROR.action({ message: ERROR_MESSAGE.PASSWORDS_DO_NOT_MATCH, type: "warning" }));
			return;
		}
		dispatch(AuthActions.REGISTER.request({
			email,
			password: hasedPassword,
		}));
	};

	return (
		<Grid container component="main" sx={{ height: 'inherit' }}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
				backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
				backgroundRepeat: 'no-repeat',
				backgroundColor: (t) =>
					t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				}}
			/>
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main', margin: '15px' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Registrieren
				</Typography>
					<Box component="form" noValidate onSubmit={_handleSubmit} sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="E-Mailadresse"
							name="email"
							type="email"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Passwort"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password2"
							label="Passwort wiederholen"
							type="password"
							id="password2"
							autoComplete="current-password"
						/>
						{/*<FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label="Remember me"
						/>*/}
						<Button
							type="submit"
							fullWidth
							variant="contained"
							disabled={isLoading}
							sx={{ mt: 3, mb: 2 }}
						>
							{isLoading ? "Laden..." : "Registrieren"}
						</Button>
						<Grid container>
						{/*<Grid item xs>
							<Link href="#" variant="body2">
								Forgot password?
							</Link>
						</Grid>*/}
						<Grid item>
							<Link component={ReactRouterLink} to="/login" variant="body2">
								{"Zur Anmeldung"}
							</Link>
						</Grid>
						</Grid>
						<Copyright sx={{ mt: 5 }} />
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}

export default RegisterSite;
