import React, { useState, useEffect } from 'react';
import { Modal, List, ListItem, ListItemText, Input, Button } from '@mui/material';

export type ModalSelectProps<T> = {
  title: string,
  items: T[],
  isOpen: boolean,
  onClose: () => void,
  onSelect: (value: T) => void,
  labelKey?: keyof T,
  valueKey?: keyof T,
  withSearch?: boolean,
};

const ModalSelect = <T extends Record<string, any>>({
  title,
  items,
  isOpen,
  onClose,
  onSelect,
  labelKey = 'label' as keyof T,
  valueKey = 'value' as keyof T,
  withSearch = false,
}: ModalSelectProps<T>) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredList, setFilteredList] = useState(items);

  useEffect(() => {
    setFilteredList(searchValue
      ? items.filter((item) => item[labelKey].toString().toLowerCase().includes(searchValue.toLowerCase()))
      : items,
    );
  }, [searchValue, items, labelKey]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 20 }}>
        <h2>{title}</h2>
        {withSearch && (
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
            fullWidth
          />
        )}
        <List>
          {filteredList.map((item, index) => (
            <ListItem
              button
              key={item[valueKey].toString()}
              onClick={() => {
                onSelect(item);
                onClose();
              }}
            >
              <ListItemText primary={item[labelKey]} />
            </ListItem>
          ))}
        </List>
        <Button onClick={onClose} color="primary">Close</Button>
      </div>
    </Modal>
  );
};

export default ModalSelect;