import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

//actions
import CoreActions from "Redux/Core.actions";

//hooks
import useGetConfig from "Hooks/General/useGetConfig";

const ConfigTracker: FC = () => {
	const dispatch = useDispatch();
	const config = useGetConfig();

	useEffect(() => {
		dispatch(CoreActions.UPDATE_CONFIG.action(config));
	}, [config]);

	return null;
};

export default ConfigTracker;
