import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import App from './App';
import { store, initializeStore, persistor } from './Redux/store';

//CLI for development
import Cli from "Utils/Cli";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

//if (process.env.NODE_ENV === 'development') {
window.app = new Cli(store);
//}

//Render the React application with a callback for when rehydration is complete
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate 
                loading={null} 
                persistor={persistor}
                onBeforeLift={async () => {
					console.log("onBeforeLift - before rehydration");
					try {
						setTimeout(() => {
							console.log("onBeforeLift - after rehydration");
							initializeStore().catch(error => {
								console.error("Error initializing store after rehydration: ", error);
							})
						}, 0);
					} catch (error) {
						console.error("Error initializing store after rehydration: ", error);
					}
				}}
            >
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
);
